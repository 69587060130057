import React, { Component, createRef } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ThreeDots } from 'react-loader-spinner';
import { generateId, isNull, isUndefined } from '../../tools/helpers';
import {
  getCurrentWeek,
  getNextWeek,
  getThisWeekDateRange,
  getNextWeekDateRange,
} from '../../tools/date.util';

import Tabs from './Tabs';
import Trips from './Trips';

import './bookings.scss';
import axios from 'axios';
import BasicSelect from '../select/BasicSelect';
import Select from 'react-select';
import { Steps, Hints } from 'intro.js-react';

class Bookings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentWeek: undefined,
      nextWeek: undefined,
      trips: undefined,
      selectedWeek: undefined,
      selectedTab: undefined,
      selectedTrips: undefined,
      selectedItems: [],
      employees: [],
      timeslots: [],
      companies: [],
      locations: {},
      company: undefined,
      week: undefined,
      loading: false,
      success: false,
      failure: false,
      alreadyGrouped: false,
      deleted: false,
      duplicate: false,
      duplicateMessage: '',
      duplicateToDate: false,
      duplicateToDateMessage: 'Please select a date to duplicate trip(s) to',
      errorMessage: null,
      edited: false,
      selectedGeneralCompanyAddress: '',
      selectedGeneralTimeslot: '',
      selectedGeneralTripType: '',
      selectedGeneralEmployees: [],
      selectedGeneralTrips: [],
      generalTripTypes: [
        { label: 'Inbound', value: 'inbound' },
        { label: 'Outbound', value: 'outbound' },
      ],
      generalCompanyAddresses: [],
      selectedTask: 'bulk_booking',
      taskTypes: ['bulk_bookings', 'csv_bookings', 'csv_upload'],
      trained: props.trained,
      introState: {
        stepsEnabled: false,
        initialStep: 0,
        options: {
          showProgress: true,
          showBullets: false,
          tooltipClass: 'etapathTooltip',
        },
        steps: [
          {
            element: '.bulkBookingIntro',
            title:
              "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Bulk Booking",
            intro:
              'This is the bulk booking page. A large amount of trips can be booked here easily and quickly.</span>',
            position: 'left',
          },
          {
            element: '.bookTripsIntro',
            title:
              "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Bulk Bookings",
            intro:
              "Select the company and the week here. <br><br>  <span class='tooltip-note'><b><i class='fas fa-comment-dots'></i></b> Weeks run from Monday to Sunday.</span>",
            position: 'left',
          },
          {
            element: '.createBookingsIntro',
            title:
              "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Create Bookings",
            intro:
              'Populate these entries to create a number of bookings easily.',
            position: 'left',
          },
          {
            element: '.passengerSelectionIntro',
            title:
              "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Passenger Selection",
            intro:
              "Start by typing the first few letters of the passengers name. <br><br>  <span class='tooltip-note'><b><i class='fas fa-comment-dots'></i></b> Select a number of passengers from the drop down to create multiple bookings</span>",
            position: 'left',
          },
          {
            element: '.timeslotIntro',
            title:
              "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Timeslot",
            intro: 'Select general timeslot.',
            position: 'left',
          },
          {
            element: '.tripTypeIntro',
            title:
              "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Inbound/Outbound Trips",
            intro:
              'Trips are either inbound from home to work or outbound from work to home.',
            position: 'left',
          },
          {
            element: '.companyAddressIntro',
            title:
              "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Company Address",
            intro: 'Select the company address.',
            position: 'left',
          },
          {
            element: '.duplicateTripIntro',
            title:
              "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Duplicate Trips",
            intro:
              'Individual entries can be selected here and duplicated to other days or weeks.',
          },
          {
            element: '.tripEditIntro',
            title:
              "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Editing a Trip",
            intro: 'Change individual entries by using the dropdown menus.',
            position: 'left',
          },
          {
            element: '.tripActionsIntro',
            title:
              "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Duplicate/Delete Trips",
            intro: 'Duplicate or delete trips by selecting these buttons.',
            position: 'left',
          },
          {
            element: '.saveTripIntro',
            title:
              "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Update Trip",
            intro:
              "Saving the bookings is the final step in creating the bookings. <br><br>  <span class='tooltip-note'><b><i class='fas fa-comment-dots'></i></b> A SMS is sent to the passenger alerting him/her of the booking once saved</span>",
          },
        ],
      },
    };

    this.companyRef = createRef();
    this.weekRef = createRef();

    this.generalCompanyAddressRef = createRef();
    this.generalTimeslotRef = createRef();
    this.generalTripTypeRef = createRef();
    this.generalEmployeesRef = createRef();
  }

  componentDidMount() {
    void this.fetchCompanies();
    void this.fetchTimeslots();

    const today = moment().format('DD-MM-YYYY');
    const currentWeek = getCurrentWeek();
    const nextWeek = getNextWeek();

    this.setState({
      trips: {},
      currentWeek,
      nextWeek,
      selectedWeek: currentWeek,
      selectedTab: today,
      selectedTrips: [],
      selectedGeneralEmployees: [],
      selectedGeneralCompanyAddress: '',
      selectedGeneralTimeslot: '',
      selectedGeneralTripType: '',
      selectedGeneralTrips: [],
    });
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const row = this.state.row;
    const matchingTrip = this.state.selectedTrips.find(
      (trip) => trip.key === this.state.key
    );

    if (!!matchingTrip) {
      matchingTrip.empRef.current.value =
        row.empRef?.current?.value ?? row.user_id;
      matchingTrip.slotRef.current.value = this.state.timeslots.find(
        (slot) => slot.label == row.timeslot
      )?.value;

      if (!!matchingTrip.pickupRef.current) {
        matchingTrip.pickupRef.current.value =
          row.pickupRef?.current?.value ?? row.location;
      }

      if (!!matchingTrip.dropOffRef.current) {
        matchingTrip.dropOffRef.current.value =
          row.dropOffRef?.current?.value ?? row.destination;
      }

      this.setState({
        selectedTrips: this.state.selectedTrips,
        row: undefined,
        key: undefined,
      });
    }

    if (prevState.company != this.state.company) {
      void this.fetchEmployees();
    }

    if (
      this.props.goToBookings == undefined &&
      this.state.trained == false &&
      this.state.companies.length != 0 &&
      this.weekRef.current != null &&
      this.companyRef.current != null &&
      this.state.employees.length != 0 &&
      this.state.week == undefined &&
      this.state.company == undefined
    ) {
      if (!this.state.trained) {
        const demoCompany = this.state.companies.filter(
          (company) => company.label == 'Demo Company'
        )[0];
        this.companyRef.current.value = demoCompany.value;
        await this.selectCompany({ target: { value: demoCompany.value } });

        this.weekRef.current.value = 'this_week';
        await this.selectWeek({ target: { value: 'this_week' } });

        setTimeout(() => {
          this.setState({
            introState: {
              ...this.state.introState,
              stepsEnabled: true,
            },
          });
        }, 1000);
      }
    }

    if (
      this.props.goToBookings != undefined &&
      this.state.companies.length != 0 &&
      this.weekRef.current != null &&
      this.companyRef.current != null
    ) {
      if (this.state.employees.length == 0) {
        const { company_id } = this.props.goToBookings;
        this.setState({ company: company_id });
      } else {
        this.props.setGoToBookings(undefined);

        const { date, company_id } = this.props.goToBookings;
        const { currentWeek, nextWeek, trips } = this.state;
        const today = moment().format('DD-MM-YYYY');
        let day = moment(date).format('DD-MM-YYYY');

        let dayInCurrentWeek = currentWeek.find(
          (element) => element.value == day
        );
        let dayInNextWeek = nextWeek.find((element) => element.value == day);
        //company is selected here
        const selectedDay =
          dayInNextWeek != undefined || dayInCurrentWeek != undefined
            ? day
            : today;
        const foundTrips = await this.fetchTrips(company_id, selectedDay);
        foundTrips.forEach((item) => {
          this.changeEmployee({ target: { value: item.user_id } });
        });
        const values = foundTrips.map((item) => ({
          ...item,
          key: generateId(),
          checked: false,
        }));

        this.setState({
          company: company_id,
          selectedWeek: dayInNextWeek != undefined ? nextWeek : currentWeek,
          selectedTab: selectedDay,
          week: dayInNextWeek != undefined ? 'next_week' : 'this_week',
          selectedTrips: values,
          trips: {
            ...trips,
            [selectedDay]: values,
          },
          selectedItems: [],
        });

        // get company addresses
        let generalCompanyAddresses = [];
        if (this.state.employees.length != 0) {
          this.state.employees[0].item.company_addresses?.forEach((address) => {
            generalCompanyAddresses.push({
              label: `${address}`,
              value: `${address}`,
            });
          });
          this.setState({ generalCompanyAddresses });
        }

        this.companyRef.current.value = company_id;
        this.weekRef.current.value =
          dayInNextWeek != undefined ? 'next_week' : 'this_week';
      }
    }
  }

  fetchEmployees = async () => {
    const response = await axios(
      `/bulk_bookings/employees?company_id=${this.state.company}`,
      {
        headers: { Accept: 'application/json' },
      }
    );

    const { data } = response;
    const employees = data.map((item) => ({
      label: `${item.name} ${item.surname}`,
      value: item.id,
      item,
    }));

    let generalCompanyAddresses = [];
    if (employees.length != 0) {
      data[0].company_addresses?.forEach((address) => {
        generalCompanyAddresses.push({
          label: `${address}`,
          value: `${address}`,
        });
      });
    }

    this.setState({ employees, generalCompanyAddresses });
  };

  fetchCompanies = async () => {
    const response = await axios("/active_companies", {
      headers: { Accept: "application/json" },
    });

    const { data } = response;
    const companies = data.companies.map((item) => ({
      label: item.name,
      value: item.id,
      item,
    }));
    this.setState({ companies });
  };

  fetchTimeslots = async () => {
    const response = await axios('/bulk_bookings/time_slots', {
      headers: { Accept: 'application/json' },
    });

    const { data } = response;

    const timeslots = Object.entries(data).map(([key, value]) => ({
      label: key,
      value: value,
    }));

    this.setState({ timeslots });
  };

  changeLocation = (trip) => {
    const { selectedTrips, employees, selectedGeneralTrips } = this.state;
    const foundTrip = [...selectedTrips, ...selectedGeneralTrips].find(
      (item) => item.key === trip.key
    );
    if (
      trip.pickupRef.current.value !== '' &&
      trip.dropOffRef.current.value !== ''
    ) {
      const foundEmployee = employees.find(
        (emp) => emp.value == foundTrip.empRef.current.value
      );
      const outbound =
        trip.pickupRef.current.value === foundEmployee.item.address_primary;
      const other =
        trip.dropOffRef.current.value === foundEmployee.item.address_primary;
      foundTrip.trip_type =
        outbound && other ? 'other' : outbound ? 'inbound' : 'outbound';
    }

    this.setState({ selectedTrips });
  };

  changeEmployee = (e) => {
    const {
      target: { value },
    } = e;
    const employee = this.state.employees.find(
      (emp) => emp.value == value
    ).item;

    const items = [];

    this.populateAddresses(items, employee.address_primary);

    employee.company_addresses?.forEach((address) => {
      items.push({
        label: `(WORK) ${address}`,
        value: `${address}`,
      });
    });

    this.setState({
      locations: {
        ...this.state.locations,
        [employee.id]: items,
      },
    });
  };

  populateAddresses = (items, address) => {
    if (!isNull(address) && !isUndefined(address)) {
      items.push({
        label: `(HOME) ${address}`,
        value: `${address}`,
      });
    }
  };

  fetchTrips = async (company, date) => {
    const response = await axios(
      `/trips/bulk_bookings?company=${company}&date_filter=${date}&per_page=500`,
      {
        headers: { Accept: 'application/json' },
      }
    );
    const { data } = response;
    let compare = (a, b) => (a > b) - (a < b);

    return data.trips;
    /*return data.trips.sort(function(a, b) {
            return compare(a.timeslot, b.timeslot) || compare(a.name, b.name) || compare(a.trip_type, b.trip_type)
        })*/
  };

  selectDay = async (date) => {
    const { trips, company } = this.state;
    this.setState({ loading: true });
    const foundTrips = await this.fetchTrips(company, date);
    this.setState({ loading: false });

    foundTrips.forEach((item) => {
      this.changeEmployee({ target: { value: item.user_id } });
    });

    const values = foundTrips.map((item) => ({
      ...item,
      key: generateId(),
      checked: false,
    }));
    this.setState({
      selectedTab: date,
      selectedTrips: values,
      selectedItems: [],
      trips: {
        ...trips,
        [date]: values,
      },
      selectedGeneralEmployees: [],
      selectedGeneralCompanyAddress: '',
      selectedGeneralTimeslot: '',
      selectedGeneralTripType: '',
      selectedGeneralTrips: [],
    });
  };

  showButtons = () => {
    const today = moment().format('YYYY-MM-DD');
    let selectedDate = moment(this.state.selectedTab, 'DD-MM-YYYY').format(
      'YYYY-MM-DD'
    );
    let isBefore = moment(selectedDate).isBefore(today);

    if (isBefore == true) {
      return 'hidden';
    } else {
      return 'visible';
    }
  };

  onCopy = (row) => {
    const { selectedTrips } = this.state;
    const key = generateId();
    const updatedRow = {
      ...row,
      id: null,
      user_id: row.empRef?.current?.value,
      key: key,
      user: null,
      new: true,
      empRef: createRef(),
      slotRef: createRef(),
      pickupRef: createRef(),
      dropOffRef: createRef(),
    };
    selectedTrips.push(updatedRow);

    this.setState({ selectedTrips, key, row });
  };

  onEdit = (row) => {
    const { trips, selectedTrips, selectedTab } = this.state;
    const key = generateId();
    const updatedRow = {
      ...row,
      id: row.id,
      user_id: row.empRef?.current?.value,
      key: key,
      user: null,
      new: true,
      empRef: createRef(),
      slotRef: createRef(),
      pickupRef: createRef(),
      dropOffRef: createRef(),
      edited: true,
    };
    selectedTrips.push(updatedRow);
    this.setState({ selectedTrips, key, row });
    const updatedTrips = selectedTrips.filter((trip) => trip.key !== row.key);

    this.setState({
      selectedTrips: updatedTrips,
      trips: {
        ...trips,
        [selectedTab]: updatedTrips,
      },
      edited: true,
    });
  };

  onUpdate = async (row) => {
    const { selectedTab } = this.state;
    try {
      this.setState({ loading: true });
      await axios('/trips/' + row.id, {
        headers: { Accept: 'application/json' },
        data: { trip: row },
        method: 'PUT',
      });

      window.scrollTo(0, 0);
      await this.selectDay(selectedTab);

      setTimeout(() => {
        this.setState({ success: false });
      }, 5000);
      this.setState({ success: true, loading: false });
    } catch (e) {
      window.scrollTo(0, 0);

      if (e?.response?.data?.error) {
        this.setState({ errorMessage: e.response.data.error });
      }

      setTimeout(() => {
        this.setState({ failure: false, errorMessage: null });
      }, 5000);
      await this.selectDay(selectedTab);
      this.setState({
        success: false,
        failure: true,
        deleted: false,
        duplicate: false,
        loading: false,
      });
    }
  };

  onDelete = async (row) => {
    const {
      trips,
      selectedTrips,
      selectedTab,
      selectedGeneralTrips,
      selectedGeneralEmployees,
    } = this.state;
    const updatedTrips = selectedTrips.filter((trip) => trip.key !== row.key);

    if (row.general && row.general == true) {
      const updatedGeneralTrips = selectedGeneralTrips.filter(
        (trip) => trip.key !== row.key
      );
      const updatedSelectedGeneralEmployees = selectedGeneralEmployees.filter(
        (emp) => emp.value !== row.user_id
      );
      this.setState({
        selectedGeneralTrips: updatedGeneralTrips,
        selectedGeneralEmployees: updatedSelectedGeneralEmployees,
      });
    }

    this.setState({
      selectedTrips: updatedTrips,
      trips: {
        ...trips,
        [selectedTab]: updatedTrips,
      },
    });

    if (row?.id) {
      try {
        this.setState({ loading: true });
        await axios('/trips/' + row.id + '?context=bulkbooking', {
          headers: { Accept: 'application/json' },
          data: { trip: row },
          method: 'DELETE',
        });

        window.scrollTo(0, 0);
        await this.selectDay(selectedTab);
        setTimeout(() => {
          this.setState({ deleted: false });
        }, 5000);
        this.setState({
          deleted: true,
          failure: false,
          success: false,
          duplicate: false,
          loading: false,
          alreadyGrouped: false,
        });
      } catch (e) {
        window.scrollTo(0, 0);
        setTimeout(() => {
          this.setState({
            success: false,
            failure: false,
            deleted: false,
            duplicate: false,
            alreadyGrouped: false,
          });
        }, 5000);
        await this.selectDay(selectedTab);
        if (e.response.status == 401) {
          this.setState({
            success: false,
            failure: false,
            deleted: false,
            duplicate: false,
            loading: false,
            alreadyGrouped: true,
          });
        } else {
          this.setState({
            success: false,
            failure: true,
            deleted: false,
            duplicate: false,
            loading: false,
            alreadyGrouped: false,
          });
        }
      }
    }
  };

  onAdd = () => {
    const { selectedTrips, selectedTab, company } = this.state;
    selectedTrips.push({
      key: generateId(),
      checked: false,
      user_id: '',
      timeslot: '',
      location: '',
      destination: '',
      trip_type: '',
      company_id: company,
      date: selectedTab,
      new: true,
      empRef: createRef(),
      slotRef: createRef(),
      pickupRef: createRef(),
      dropOffRef: createRef(),
    });

    this.setState({ selectedTrips });
    //this.scrollToBottom();
  };

  onSelect = (row) => {
    const { selectedItems } = this.state;
    const matchingItem = selectedItems.find((item) => item.key === row.key);
    if (!!matchingItem) {
      const updatedItems = selectedItems.filter((item) => item.key !== row.key);
      this.setState({ selectedItems: updatedItems });
    } else {
      selectedItems.push(row);
      this.setState({ selectedItems });
    }
  };

  onDuplicate = async (date) => {
    if (!date) {
      setTimeout(() => {
        this.setState({ duplicateToDate: false });
      }, 5000);
      window.scrollTo(0, 0);
      return this.setState({ duplicateToDate: true });
    }

    const { trips, selectedItems, company } = this.state;
    this.setState({ loading: true });
    const foundTrips = await this.fetchTrips(company, date);
    this.setState({ loading: false });
    const existingTrips = trips[date] ?? [];
    const updatedItems = selectedItems.map((item) => ({
      ...item,
      id: null,
      key: generateId(),
      date,
    }));

    const concatTrips = existingTrips.concat(updatedItems);
    const finalTrips = {
      ...trips,
      [date]: concatTrips,
    };

    const { selectedTab } = this.state;
    const tripsToSave = concatTrips.map((item) => ({
      id: item.id,
      region: item.region,
      created_at: item.created_at,
      updated_at: item.updated_at,
      location_latitude: item.location_latitude,
      location_longitude: item.location_longitude,
      destination_latitude: item.destination_latitude,
      destination_longitude: item.destination_longitude,
      grouped_trip_id: null,
      status: 0,
      location_long_lat: item.location_long_lat,
      destination_long_lat: item.destination_long_lat,
      estimate: item.estimate,
      user_id: item.empRef?.current?.value ?? item.user_id,
      timeslot: item.slotRef?.current?.key ?? Number(item.timeslot),
      location: item.pickupRef?.current?.value ?? item.location,
      destination: item.dropOffRef?.current?.value ?? item.destination,
      date: item.date,
      trip_type: item.trip_type,
      company_id: this.companyRef.current.value,
      distance: item.distance ?? 0,
    }));

    for (let i = 0; i < foundTrips.length; i++) {
      const tripToCompare = foundTrips[i];
      for (let i = 0; i < tripsToSave.length; i++) {
        const trip = tripsToSave[i];
        const filteredTrips =
          tripToCompare.user_id == trip.user_id &&
          tripToCompare.destination == trip.destination &&
          tripToCompare.location == trip.location &&
          tripToCompare.timeslot == trip.timeslot &&
          moment(tripToCompare.date).format('DD-MM-YYYY') == trip.date;

        if (filteredTrips) {
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.setState({ duplicate: false });
          }, 10000);
          const message = `Trip with "${tripToCompare.location}", and "${tripToCompare.destination}" at
                                "${tripToCompare.timeslot}" already exists!`;
          return this.setState({
            success: false,
            failure: false,
            deleted: false,
            duplicate: true,
            duplicateMessage: message,
          });
        }
      }
    }

    try {
      this.setState({ loading: true });
      await axios('/bulk_bookings/save_trips', {
        headers: { Accept: 'application/json' },
        data: { trips: tripsToSave },
        method: 'POST',
      });

      window.scrollTo(0, 0);
      await this.selectDay(selectedTab);
      this.setState({
        success: true,
        failure: false,
        deleted: false,
        duplicate: false,
        loading: false,
        selectedItems: [],
        trips: finalTrips,
      });

      setTimeout(() => {
        this.setState({ success: false });
      }, 5000);
    } catch (e) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.setState({
          success: false,
          failure: false,
          deleted: false,
          duplicate: false,
          selectedItems: [],
          trips: finalTrips,
        });
      }, 5000);
      await this.selectDay(selectedTab);
      this.setState({
        success: false,
        failure: true,
        deleted: false,
        duplicate: false,
        loading: false,
        selectedItems: [],
        trips: finalTrips,
      });
    }
  };

  onSelectAll = (checked) => {
    const { selectedTrips } = this.state;
    const updatedTrips = selectedTrips.map((item) => ({ ...item, checked }));
    const selectedItems = updatedTrips.filter((item) => item.checked);

    this.setState({ selectedTrips: updatedTrips, selectedItems });
  };

  changeCompany = () => {
    this.setState({ company: this.companyRef.current.value });
  };

  selectCompany = async ({ target: { value } }) => {
    this.weekRef.current.value = '';
    this.setState({ company: value, week: undefined });
  };

  selectWeek = ({ target: { value } }) => {
    let selectedWeek = [];
    const today = moment().format('DD-MM-YYYY');
    switch (value) {
      case 'this_week':
        selectedWeek = this.state.currentWeek;
        void this.selectDay(today);
        break;
      case 'next_week':
        selectedWeek = this.state.nextWeek;
        void this.selectDay(selectedWeek[0].value);
        break;
    }

    this.setState({ week: value, selectedWeek });
  };

  onSave = async () => {
    const { selectedTrips, selectedTab, selectedGeneralTrips } = this.state;
    let tripsToSave = [];
    let duplicatedTrip = '';
    const trips = [...selectedTrips, ...selectedGeneralTrips].map((item) => ({
      id: item.id,
      region: item.region,
      created_at: item.created_at,
      updated_at: item.updated_at,
      location_latitude: item.location_latitude,
      location_longitude: item.location_longitude,
      destination_latitude: item.destination_latitude,
      destination_longitude: item.destination_longitude,
      grouped_trip_id: null,
      status: 0,
      location_long_lat: item.location_long_lat,
      destination_long_lat: item.destination_long_lat,
      estimate: item.estimate,
      user_id: item.empRef?.current?.value ?? item.user_id,
      timeslot: item.slotRef?.current?.value
        ? this.state.timeslots.find(
            (slot) => slot.value == item.slotRef?.current?.value
          )?.label
        : item.timeslot,
      location: item.pickupRef?.current?.value ?? item.location,
      destination: item.dropOffRef?.current?.value ?? item.destination,
      date: item.date ?? selectedTab,
      trip_type: item.trip_type,
      company_id: this.companyRef.current.value,
      distance: item.distance ?? 0,
      edited: item.edited ?? false,
    }));

    for (let i = 0; i < trips.length; i++) {
      const tripToCompare = trips[i];

      if (!!tripToCompare.id) {
        tripToCompare.date = moment(tripToCompare.date).format('DD-MM-YYYY');
      }

      const filteredTrips = trips.filter(
        (trip) =>
          tripToCompare.user_id == trip.user_id &&
          tripToCompare.destination == trip.destination &&
          tripToCompare.location == trip.location &&
          tripToCompare.timeslot == trip.timeslot &&
          tripToCompare.date == trip.date
      );

      duplicatedTrip = filteredTrips.length > 1 && trips.length;

      if (duplicatedTrip) {
        window.scrollTo(0, 0);
        setTimeout(() => {
          this.setState({ duplicate: false });
        }, 10000);
        const message = `Trip with "${tripToCompare.location}", and "${tripToCompare.destination}" at
                                "${tripToCompare.timeslot}" already exists!`;
        return this.setState({
          success: false,
          failure: false,
          deleted: false,
          duplicate: true,
          duplicateMessage: message,
        });
      } else {
        if (tripToCompare.id && tripToCompare.edited) {
          delete tripToCompare.edited;
          await this.onUpdate(tripToCompare);
        } else {
          delete tripToCompare.edited;
          tripsToSave.push(tripToCompare);
        }
      }
    }

    try {
      this.setState({ loading: true });
      let response = await axios('/bulk_bookings/save_trips', {
        headers: { Accept: 'application/json' },
        data: { trips: tripsToSave },
        method: 'POST',
      });

      window.scrollTo(0, 0);
      await this.selectDay(selectedTab);
      if (response.data.saved_trips.length != 0) {
        setTimeout(() => {
          this.setState({ success: false });
        }, 5000);

        this.setState({
          success: true,
          failure: false,
          deleted: false,
          duplicate: false,
          loading: false,
        });
      }
    } catch (e) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.setState({
          success: false,
          failure: false,
          deleted: false,
          duplicate: false,
        });
      }, 5000);
      await this.selectDay(selectedTab);
      this.setState({
        success: false,
        failure: true,
        deleted: false,
        duplicate: false,
        loading: false,
      });
    }
  };

  onChangeGeneralCompanyAddress = (event) => {
    let selectedGeneralCompanyAddress =
      this.generalCompanyAddressRef.current.value;
    const { selectedGeneralTrips, selectedGeneralTripType } = this.state;
    selectedGeneralTrips.forEach((trip) => {
      trip.location =
        selectedGeneralTripType == ''
          ? ''
          : selectedGeneralTripType == 'inbound'
          ? trip.emp.address_primary
          : selectedGeneralCompanyAddress == ''
          ? ''
          : selectedGeneralCompanyAddress;
      trip.destination =
        selectedGeneralTripType == ''
          ? ''
          : selectedGeneralTripType == 'inbound'
          ? selectedGeneralCompanyAddress == ''
            ? ''
            : selectedGeneralCompanyAddress
          : trip.emp.address_primary;
      trip.pickupRef.current.value =
        selectedGeneralTripType == ''
          ? ''
          : selectedGeneralTripType == 'inbound'
          ? trip.emp.address_primary
          : selectedGeneralCompanyAddress == ''
          ? ''
          : selectedGeneralCompanyAddress;
      trip.dropOffRef.current.value =
        selectedGeneralTripType == ''
          ? ''
          : selectedGeneralTripType == 'inbound'
          ? selectedGeneralCompanyAddress == ''
            ? ''
            : selectedGeneralCompanyAddress
          : trip.emp.address_primary;
    });
    this.setState({
      selectedGeneralTrips,
      selectedGeneralCompanyAddress,
    });
  };

  onChangeGeneralTimeSlot = (event) => {
    const { selectedGeneralTrips } = this.state;
    let selectedGeneralTimeslot = this.generalTimeslotRef.current.value;
    selectedGeneralTrips.forEach((trip) => {
      trip.timeslot = selectedGeneralTimeslot;
      trip.slotRef.current.value = selectedGeneralTimeslot;
    });
    this.setState({
      selectedGeneralTrips,
      selectedGeneralTimeslot,
    });
  };

  onChangeGeneralTripType = (event) => {
    const { selectedGeneralTrips, selectedGeneralCompanyAddress } = this.state;

    let selectedGeneralTripType = this.generalTripTypeRef.current.value;
    selectedGeneralTrips.forEach((trip) => {
      trip.trip_type = selectedGeneralTripType;
      trip.location =
        selectedGeneralTripType == ''
          ? ''
          : selectedGeneralTripType == 'inbound'
          ? trip.emp.address_primary
          : selectedGeneralCompanyAddress == ''
          ? ''
          : selectedGeneralCompanyAddress;
      trip.destination =
        selectedGeneralTripType == ''
          ? ''
          : selectedGeneralTripType == 'inbound'
          ? selectedGeneralCompanyAddress == ''
            ? ''
            : selectedGeneralCompanyAddress
          : trip.emp.address_primary;
      trip.pickupRef.current.value =
        selectedGeneralTripType == ''
          ? ''
          : selectedGeneralTripType == 'inbound'
          ? trip.emp.address_primary
          : selectedGeneralCompanyAddress == ''
          ? ''
          : selectedGeneralCompanyAddress;
      trip.dropOffRef.current.value =
        selectedGeneralTripType == ''
          ? ''
          : selectedGeneralTripType == 'inbound'
          ? selectedGeneralCompanyAddress == ''
            ? ''
            : selectedGeneralCompanyAddress
          : trip.emp.address_primary;
    });
    this.setState({
      selectedGeneralTrips,
      selectedGeneralTripType,
    });
  };

  onChangeGeneralEmployees = (selectedGeneralEmployees) => {
    this.setState({ selectedGeneralEmployees });
    const {
      selectedTab,
      company,
      selectedGeneralTripType,
      selectedGeneralTimeslot,
      selectedGeneralCompanyAddress,
    } = this.state;

    let selectedGeneralTrips = [];
    let locations = {};
    selectedGeneralEmployees.forEach((selectedEmp) => {
      selectedGeneralTrips.push({
        key: generateId(),
        checked: false,
        user_id: selectedEmp.value,
        timeslot: selectedGeneralTimeslot == '' ? '' : selectedGeneralTimeslot,
        location:
          selectedGeneralTripType == ''
            ? ''
            : selectedGeneralTripType == 'inbound'
            ? selectedEmp.item.address_primary
            : selectedGeneralCompanyAddress == undefined
            ? ''
            : selectedGeneralCompanyAddress,
        destination:
          selectedGeneralTripType == ''
            ? ''
            : selectedGeneralTripType == 'inbound'
            ? selectedGeneralCompanyAddress == undefined
              ? ''
              : selectedGeneralCompanyAddress
            : selectedEmp.item.address_primary,
        trip_type: selectedGeneralTripType == '' ? '' : selectedGeneralTripType,
        company_id: company,
        date: selectedTab,
        new: true,
        empRef: createRef(),
        slotRef: createRef(),
        pickupRef: createRef(),
        dropOffRef: createRef(),
        general: true,
        emp: selectedEmp.item,
      });
      let items = [];
      this.populateAddresses(items, selectedEmp.item.address_primary);

      selectedEmp.item.company_addresses?.forEach((address) => {
        items.push({
          label: `(WORK) ${address}`,
          value: `${address}`,
        });
      });
      locations[selectedEmp.value] = items;
    });

    this.setState({
      locations: {
        ...this.state.locations,
        ...locations,
      },
    });
    this.setState({ selectedGeneralTrips });
    //this.scrollToBottom();
  };

  scrollToBottom = () => {
    const trips_bottom = document.getElementById('trips_bottom');
    //trips_bottom.scrollIntoView({ behavior: 'smooth', block: 'end' });
    trips_bottom.scrollIntoView(false);
    const window_bottom = document.getElementById('window_bottom');
    window_bottom.scrollIntoView(false);
  };

  render() {
    const {
      companies,
      employees,
      timeslots,
      locations,
      selectedWeek,
      selectedTab,
      selectedTrips,
      selectedItems,
      week,
      loading,
      success,
      failure,
      alreadyGrouped,
      deleted,
      duplicate,
      duplicateMessage,
      duplicateToDate,
      duplicateToDateMessage,
      edited,
      selectedGeneralCompanyAddress,
      selectedGeneralTimeslot,
      selectedGeneralTripType,
      generalTripTypes,
      generalCompanyAddresses,
      selectedGeneralEmployees,
      selectedGeneralTrips,
      trained,
    } = this.state;

    return (
      <div className="bookings bulkBookingIntro">
        <h1 className="bookings__title">Bulk Bookings</h1>

        {success && (
          <h3
            style={{
              color: 'green',
              textAlign: 'center',
              marginBottom: '30px',
            }}>
            Trip(s) were created successfully!
          </h3>
        )}
        {failure && (
          <h3
            style={{ color: 'red', textAlign: 'center', marginBottom: '30px' }}>
              {this.state.errorMessage || "Failed to action your request!"}
          </h3>
        )}
        {alreadyGrouped && (
          <h3
            style={{ color: 'red', textAlign: 'center', marginBottom: '30px' }}>
            Error trip is already grouped!
          </h3>
        )}
        {deleted && (
          <h3
            style={{
              color: 'green',
              textAlign: 'center',
              marginBottom: '30px',
            }}>
            Trip was deleted successfully!
          </h3>
        )}
        {duplicate && (
          <h3
            style={{ color: 'red', textAlign: 'center', marginBottom: '30px' }}>
            {duplicateMessage}
          </h3>
        )}
        {duplicateToDate && (
          <h3
            style={{ color: 'red', textAlign: 'center', marginBottom: '30px' }}>
            {duplicateToDateMessage}
          </h3>
        )}
        <div className="bookTripsIntro">
          {this.state.companies.length == 0 ? (
            <div className="flex justify-center mt-10">
              <ThreeDots
                type="ThreeDots"
                color="#9B999E"
                height="100"
                width="100"
              />
            </div>
          ) : (
            <div className="bookings__filters">
              <BasicSelect
                placeholder="Select Company"
                items={companies}
                onChange={this.selectCompany}
                dropdownRef={this.companyRef}
              />
              <BasicSelect
                placeholder="Select Week"
                onChange={this.selectWeek}
                items={[
                  { label: getThisWeekDateRange(), value: 'this_week' },
                  { label: getNextWeekDateRange(), value: 'next_week' },
                ]}
                dropdownRef={this.weekRef}
              />
            </div>
          )}
          {week && (
            <div className="bookings__tabs">
              <Tabs
                items={selectedWeek}
                onClick={this.selectDay}
                selected={selectedTab}
              />
            </div>
          )}
        </div>
        {loading && (
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: '100%',
              marginTop: '30px',
            }}>
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        )}

        {!loading && week && selectedTrips && (
          <>
            <div className="createBookingsIntro">
              {employees.length != 0 && (
                <div className="bookings__general_employees passengerSelectionIntro">
                  <Select
                    ref={this.generalEmployeesRef}
                    placeholder={'Multi Select Employees'}
                    isMulti
                    isSearchable
                    options={employees}
                    onChange={this.onChangeGeneralEmployees}
                    closeMenuOnSelect={false}
                    value={selectedGeneralEmployees}
                  />
                </div>
              )}

              {
                //(selectedGeneralEmployees.length != 0) &&
                employees.length != 0 && (
                  <div className="bookings__filters">
                    <div className="timeslotIntro">
                      <BasicSelect
                        placeholder="Select General Timeslot"
                        onChange={this.onChangeGeneralTimeSlot}
                        items={timeslots}
                        dropdownRef={this.generalTimeslotRef}
                      />
                    </div>
                    <div className="tripTypeIntro">
                      <BasicSelect
                        placeholder="Select General Trip Type"
                        onChange={this.onChangeGeneralTripType}
                        items={generalTripTypes}
                        dropdownRef={this.generalTripTypeRef}
                      />
                    </div>
                    <div className="companyAddressIntro">
                      <BasicSelect
                        placeholder="Select General Company Address"
                        items={generalCompanyAddresses}
                        onChange={this.onChangeGeneralCompanyAddress}
                        dropdownRef={this.generalCompanyAddressRef}
                      />
                    </div>
                  </div>
                )
              }
            </div>

            <div className="bookings__table">
              <Trips
                trained={trained}
                locations={locations}
                timeslots={timeslots}
                employees={employees}
                trips={selectedTrips}
                changeEmployee={this.changeEmployee}
                changeLocation={this.changeLocation}
                onCopy={this.onCopy}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
                showAdd={this.showButtons()}
                onAdd={this.onAdd}
                onSelect={this.onSelect}
                onSelectAll={this.onSelectAll}
                onDuplicate={this.onDuplicate}
                onSave={this.onSave}
                selected={selectedItems.length > 0}
                editedTrip={edited}
                selectedGeneralEmployees={selectedGeneralEmployees}
                selectedGeneralTrips={selectedGeneralTrips}
              />
            </div>
          </>
        )}
        <Steps
          enabled={this.state.introState.stepsEnabled}
          steps={this.state.introState.steps}
          initialStep={this.state.introState.initialStep}
          onExit={() => {}}
          options={this.state.introState.options}
        />
      </div>
    );
  }
}

export default Bookings;
