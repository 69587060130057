/*
Example usage:
<div data-controller="introjs" data-introjs-intro-name-value="dashboard" data-introjs-auto-start-value="true|false">
  <!-- manual start the intro tour -->
  <button data-action="click->introjs#startIntro">Start Intro</button>

  <button data-intro="This is a clickable button" data-clickable-target-selector="#myButton">Click me</button>
  <p data-intro="This is a non-clickable paragraph">Some text</p>
</div>
*/

import { Controller } from "stimulus"
import introJs from "intro.js"

export default class extends Controller {
  static values = {
    introName: String,
    autoStart: Boolean
  }

  connect() {
    // Dont start if the user has already seen the intro && it is set to auto start
    if (this.shouldAutoStart() && !this.hasSeenIntro()) {
      return
    }

    // Setup introjs
    this.introjs = this.setupIntroJs()

    // Autostart the intro if the user has not seen it and it is set to auto start
    if (this.hasAutoStartValue && this.autoStartValue) {
      this.startIntro()
    }
  }


  // Setup introjs options and event handlers
  setupIntroJs() {
    const introjs = introJs()

    introjs.setOptions({tooltipClass: 'etapathTooltip'})

    this.clickedElements = []
    introjs.onbeforechange(async (element) => {
      // Prevent double clicking the same element (sometimes network requests happen and that causes it to slow down again)
      if (element.dataset.clickableTargetSelector && !this.clickedElements.includes(element.dataset.clickableTargetSelector)) {
        await new Promise(resolve => {
          document.querySelector(element.dataset.clickableTargetSelector).click()
          this.clickedElements.push(element.dataset.clickableTargetSelector)

          // Waiting for the side effects of the click to take place
          setTimeout(resolve, 2000)
        })
      }
    })

    introjs.oncomplete(() => {
      this.setHasSeenIntro()
    })

    return introjs
  }

  // Start the introjs tour
  startIntro() {
    this.introjs.start()
  }

  // Should the tour start automatically?
  shouldAutoStart() {
    return this.hasAutoStartValue && this.autoStartValue
  }

  // Has the user seen the intro?
  hasSeenIntro() {
    return localStorage.getItem(`hasSeenIntro_${this.introNameValue}`)
  }

  // User has seen the intro
  setHasSeenIntro() {
    localStorage.setItem(`hasSeenIntro_${this.introNameValue}`, 'true')
  }
}