import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "tripDetails", "loadingOverlay" ]
  static classes = ['selectedTrip']

  connect() {
    window.ReactRailsUJS.mountComponents()
    this.tripDetailsTarget.addEventListener('turbo:frame-load', () => {
      this.hideLoadingOverlay()
    })
  }

  trip(event) {
    // Get the closest parent <tr> element
    let element;
    if (event.target.tagName === 'TD') {
      element = event.target.closest('tr');
    } else {
      element = event.target.closest('.track-trip-summary');
    }

    const tripId = element.dataset.tripId

    // IF the trip id is not present, do not proceed to open the trip details
    // Becaause user could be clicking on the hamburger menue to get to the whatsapp message or whatsapp call
    if (!tripId) {
      return
    }

    event.preventDefault();

    const urlParams = new URLSearchParams(window.location.search);
    const tripView = urlParams.get('trip_view') || 'list';
    const tripPath = `/control_room/trips/${tripId}?trip_view=${tripView}`

    this.showLoadingOverlay()
    this.tripDetailsTarget.setAttribute('src', tripPath)
    this.highlightSelectedTrip(tripId, tripView)
  }

  fetchActivities(event) {
    event.preventDefault();
    const tripId = event.target.dataset.tripId
    const activitiesPath = `/activities?grouped_trip_id=${tripId}`
    const activitiesFrame = document.getElementById('activities')
    if (activitiesFrame) {
      // Hide the button and show the loader
      const tripActivityLoader = document.querySelector('.trip-activity-loader')
      tripActivityLoader.classList.remove('hidden')
      event.target.classList.add('hidden')

      activitiesFrame.setAttribute('src', activitiesPath)
    }
  }

  showLoadingOverlay() {
    this.loadingOverlayTarget.classList.remove('hidden')
  }

  hideLoadingOverlay() {
    this.loadingOverlayTarget.classList.add('hidden')
  }

  highlightSelectedTrip(tripId, tripView) {
    const tripSelectorPrefix = tripView === 'list' ? 'summary_grouped_trip_' : 'tracking_summary_grouped_trip_'
    const tripSelector = `${tripSelectorPrefix}${tripId}`

    document.querySelectorAll(`[id^='${tripSelectorPrefix}']`).forEach(row => {
      row.classList.remove(this.selectedTripClass)
    });

    // Highlight the selected row
    const selectedRow = document.getElementById(tripSelector);
    if (selectedRow) {
      selectedRow.classList.add(this.selectedTripClass);
    }
  }
}