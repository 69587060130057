// Example usage:
//
// <div data-controller="dropdown">
//   <button data-action="click->dropdown#toggle">Toggle Dropdown</button>
//   <div data-dropdown-target="menu" class="hidden">
//     <!-- Dropdown menu items -->
//     <a href="#">Item 1</a>
//     <a href="#">Item 2</a>
//     <a href="#">Item 3</a>
//   </div>
// </div>


import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["menu"]

  connect() {
    // Add event listener to close dropdown when clicking outside
    document.addEventListener("click", this.closeDropdown.bind(this))
  }

  disconnect() {
    // Remove event listener when controller is disconnected
    document.removeEventListener("click", this.closeDropdown.bind(this))
  }

  toggle(event) {
    event.stopPropagation()
    this.menuTarget.classList.toggle("hidden")
  }

  closeDropdown(event) {
    if (!this.element.contains(event.target)) {
      this.menuTarget.classList.add("hidden")
    }
  }
}
