import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["trip", "driver", "passengers", "actions", "activities", "map", "tracking"]

  moveUp(event) {
    const elementName = event.currentTarget.dataset.elementName
    const targetElement = this.getTargetForElement(elementName)

    if (targetElement) {
      this.updateOrder(targetElement)
    }
  }

  getTargetForElement(elementName) {
    const targetName = `${elementName}Target`
    return this[targetName]
  }

  updateOrder(targetElement) {
    const otherElements = Array
      .from(this.element.children)
      .filter(child => child.dataset.reorderableListTarget && child !== targetElement)

    const list = [targetElement, ...otherElements]

    // Remove all existing elements and add them back in the new order
    list.forEach(el => el.remove())
    list.forEach((el) => {
      this.element.appendChild(el);
    });

    // Scroll the container to the top
    this.element.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}