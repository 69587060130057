import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const DynamicSelect = ({ props }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const selectId = `${props.hiddenInputId}_select`;

  const options = props.resetOption
    ? [{ label: props.resetOption, value: null }, ...props.options]
    : props.options;

  useEffect(() => {
    if (props.value) {
      const valuesArray = props.isMulti ? props.value.split(',').map(val => val.toString()) : [props.value.toString()];
      const selectedValues = options.filter(option =>
        valuesArray.includes(option.value?.toString())
      );
      handleChange(props.isMulti ? selectedValues : selectedValues[0]);
    } else if (props.resetOption) {
      handleChange(options[0]);
    }
  }, []);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    if (props.hiddenInputId) {
      const hiddenInput = document.getElementById(props.hiddenInputId);
      if (hiddenInput) {
        if (props.isMulti) {
          if (selectedOption.length > 0) {
            hiddenInput.value = selectedOption
              ? selectedOption.filter(option => !!option.value).map((option) => option.value).join(',')
              : '';
          } else {
            hiddenInput.value = '';
          }
        } else {
          hiddenInput.value = selectedOption ? selectedOption.value : '';
        }
      }
    }

    props.onCarSelected?.(selected?.value || null);
    props.setLocationDestination?.(selected?.value || null);
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? '#6b7280' : '#d1d5db',
      boxShadow: state.isFocused ? '0 0 0 1px #6b7280' : 'none',
      backgroundColor: props.backgroundColor || 'white',
      borderRadius: '0.375rem',
      fontSize: '14px',
      lineHeight: '1.25rem',
      padding: '0.275rem 0.25rem 0.275rem 0.5rem',
      cursor: 'pointer',
    }),
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? '#FFFFFF' : '#716C6E',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#716C6E',
      '&:hover': {
        color: 'white',
        backgroundColor: '#4299E1',
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: props.backgroundColor || '#FFFFFF',
    }),
  };

  return (
    <Select
      id={selectId}
      key={selectId}
      options={options}
      isMulti={props.isMulti}
      onChange={handleChange}
      value={selectedOption}
      placeholder={props.placeholder}
      styles={customStyles}
    />
  );
};

export default DynamicSelect;
