import { Controller } from "stimulus"
import { MarkerClusterer } from "@googlemaps/markerclusterer"
// Usage in HTML:
// <div data-controller="google-maps"
//      data-google-maps-grouped-trip-value="<%= @grouped_trip.to_json %>"
//      data-google-maps-trips-value="<%= @grouped_trip.trips_all_without_cancelled.to_json %>"
//      data-google-maps-bound-value="<%= @bound %>"
//      data-google-maps-route-value="<%= @grouped_trip.route %>"
//      data-google-maps-current-user-value="<%= current_user.to_json %>"
//      data-google-maps-api-key-value="<%= Rails.application.credentials.google_maps_api_key %>">
//   <div id="map" style="height: 400px; width: 100%;"></div>
// </div>

export default class extends Controller {
  static values = {
    groupedTrip: Object,
    trips: Array,
    bound: String,
    route: String,
    currentUser: Object,
    apiKey: String
  }

  static targets = ["map"]

  connect() {
    this.initializeMap()
  }

  initializeMap() {
    const styleArray = [
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e9e9e9',
          },
          {
            lightness: 17,
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e4e4e4',
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#efefef',
          },
          {
            lightness: 17,
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: '#efefef',
          },
          {
            lightness: 29,
          },
          {
            weight: 0.2,
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
          {
            color: '#efefef',
          },
          {
            lightness: 18,
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
          {
            color: '#efefef',
          },
          {
            lightness: 16,
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e4e4e4',
          },
          {
            lightness: 21,
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dedede',
          },
          {
            lightness: 21,
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            visibility: 'on',
          },
          {
            color: '#ffffff',
          },
          {
            lightness: 16,
          },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            saturation: 36,
          },
          {
            color: '#333333',
          },
          {
            lightness: 40,
          },
        ],
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
          {
            color: '#f2f2f2',
          },
          {
            lightness: 19,
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#fefefe',
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: '#fefefe',
          },
          {
            lightness: 17,
          },
          {
            weight: 1.2,
          },
        ],
      },
    ];
    const mapOptions = {
      center: {
        lat: this.boundValue === "inbound" ? this.tripsValue[0].destination_latitude : this.tripsValue[0].location_latitude,
        lng: this.boundValue === "inbound" ? this.tripsValue[0].destination_longitude : this.tripsValue[0].location_longitude
      },
      zoom: 10,
      styles: styleArray
    }

    this.LABELS = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
    this.labelCount = 0

    this.markerOrder = this.generateMarkerOrder()

    if (this.routeValue && this.currentUserValue.role !== 'fleetmanager' && this.currentUserValue.role !== 'company_admin') {
      this.renderStaticMap()
    } else if (this.currentUserValue.role !== 'fleetmanager' && this.currentUserValue.role !== 'company_admin') {
      this.renderDynamicMap(mapOptions)
    }
  }

  generateMarkerOrder() {
    let order = ""
    if (this.boundValue === "outbound") {
      order += `markers=label:${this.LABELS[this.labelCount++]}|${this.tripsValue[0].location_latitude},${this.tripsValue[0].location_longitude}`
    }

    this.tripsValue.forEach((trip) => {
      order += `${this.labelCount == 0 ? "" : "&"}markers=label:${this.LABELS[this.labelCount++]}|${this.boundValue === "inbound" ? trip.location_latitude : trip.destination_latitude
        },${this.boundValue === "inbound" ? trip.location_longitude : trip.destination_longitude
        }`
    })

    if (this.boundValue === "inbound") {
      order += `&markers=label:${this.LABELS[this.labelCount++]}|${this.tripsValue[0].destination_latitude},${this.tripsValue[0].destination_longitude}`
    }

    return order
  }

  renderStaticMap() {
    const img = document.createElement('img')
    img.style.width = "100%"
    img.src = `https://maps.googleapis.com/maps/api/staticmap?size=640x250&scale=1&key=${this.apiKeyValue}&path=color:0x0000ff|weight:2|${this.routeValue}&${this.markerOrder}`
    this.mapTarget.appendChild(img)
    this.mapTarget.style.width = "100%"
  }

  renderDynamicMap(mapOptions) {
    this.map = new window.google.maps.Map(this.mapTarget, mapOptions)
    this.directionsDisplay = new window.google.maps.DirectionsRenderer()
    this.directionsDisplay.setMap(this.map)
    this.directionsDisplay.setOptions({
      suppressMarkers: true,
      suppressInfoWindows: true
    })
    this.calculateRoute()
  }

  calculateRoute() {
    const start = this.tripsValue[0].location
    const end = this.tripsValue[this.tripsValue.length - 1].destination
    const waypoints = this.generateWaypoints()

    const request = {
      origin: start,
      destination: end,
      waypoints: waypoints,
      travelMode: window.google.maps.TravelMode.DRIVING
    }

    const directionsService = new window.google.maps.DirectionsService()
    directionsService.route(request, (result, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        this.directionsDisplay.setDirections(result)
        this.addMarkers()
      }
    })
  }

  generateWaypoints() {
    const waypoints = []
    if (this.tripsValue.length > 1) {
      this.tripsValue.forEach((trip) => {
        if (this.boundValue === "inbound" && trip.location !== this.tripsValue[0].location) {
          waypoints.push({
            location: trip.location,
            stopover: true
          })
        } else if (this.boundValue === "outbound" && trip.destination !== this.tripsValue[this.tripsValue.length - 1].destination) {
          waypoints.push({
            location: trip.destination,
            stopover: true
          })
        }
      })
    }
    return waypoints
  }

  addMarkers() {
    const markers = []
    let markerCount = 0

    if (this.boundValue === "outbound") {
      markers.push(this.createMarker({
        lat: this.addCoordVariation(this.tripsValue[0].location_latitude),
        lng: this.addCoordVariation(this.tripsValue[0].location_longitude)
      }, this.LABELS[markerCount++], this.tripsValue[0].location))
    }

    this.tripsValue.forEach((trip) => {
      const position = this.boundValue === "inbound"
        ? { lat: this.addCoordVariation(trip.location_latitude), lng: this.addCoordVariation(trip.location_longitude) }
        : { lat: this.addCoordVariation(trip.destination_latitude), lng: this.addCoordVariation(trip.destination_longitude) }
      markers.push(this.createMarker(position, this.LABELS[markerCount++], this.boundValue === "inbound" ? trip.location : trip.destination))
    })

    if (this.boundValue === "inbound") {
      markers.push(this.createMarker({
        lat: this.addCoordVariation(this.tripsValue[0].destination_latitude),
        lng: this.addCoordVariation(this.tripsValue[0].destination_longitude)
      }, this.LABELS[markerCount++], this.tripsValue[0].destination))
    }

    new MarkerClusterer(this.map, markers, {
      maxZoom: 18,
      imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
    })
  }

  createMarker(position, label, content) {
    const marker = new window.google.maps.Marker({
      position,
      label,
      map: this.map,
    })

    marker.addListener("click", () => {
      this.infoWindow.setContent(content)
      this.infoWindow.open(this.map, marker)
    })

    return marker
  }

  addCoordVariation(coord) {
    return parseFloat(coord) + (Math.random() - 0.5) / 3500
  }

  openGoogleMapsLink() {
    let link = 'https://www.google.com/maps/dir/?api=1&'

    const start = `${this.tripsValue[0].location_latitude},${this.tripsValue[0].location_longitude}`
    const end = `${this.tripsValue[this.tripsValue.length - 1].destination_latitude},${this.tripsValue[this.tripsValue.length - 1].destination_longitude}`
    link += `origin=${start}&`
    link += `destination=${end}&`
    link += `waypoints=`

    const waypoints = this.generateGoogleMapsWaypoints()
    link += waypoints.join('|')

    window.open(link, '_blank').focus()
  }

  generateGoogleMapsWaypoints() {
    const waypoints = []
    if (this.tripsValue.length > 1) {
      this.tripsValue.forEach((trip) => {
        if (this.boundValue === "inbound" && trip.location !== this.tripsValue[0].location) {
          waypoints.push(`${trip.location_latitude},${trip.location_longitude}`)
        } else if (this.boundValue === "outbound" && trip.destination !== this.tripsValue[this.tripsValue.length - 1].destination) {
          waypoints.push(`${trip.destination_latitude},${trip.destination_longitude}`)
        }
      })
    }
    return waypoints
  }
}
