import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button", "source"]

  connect() {
    if (!this.hasButtonTarget) return

    this.originalContent = this.buttonTarget.innerHTML
  }

  copy(event) {
    event.preventDefault()

    const text = this.sourceTarget.innerHTML || this.sourceTarget.value

    const formattedText = text.split("|").join('\n- ');

    navigator.clipboard.writeText(formattedText).then(() => this.copied())
  }

  copied() {
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.buttonTarget.innerHTML = 'Copied!'

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalContent
    }, 2000)
  }
}